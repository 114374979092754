<script>
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WebPanelMixin from '@/core/shared/web-panel/WebPanelMixin.vue';
import { initEcho } from '@/core/echo';
import nav from './navigation-bar-items';
import { setInteractionMode } from 'vee-validate';

// NOTE(Jack): Evaluating this helper once for the entire app so that the validation extensions are available where needed
import '@/core/shared/helpers/ValidationHelper';

@Component({
  data () {
    return {
      showLogout: !this.$store.state.isEmbedded,
      config: {
        GOOGLE_MAPS_API_KEY: 'AIzaSyCMYM5mSgHnOwnd9faZDBcHm-zM-5FCxho',
      },
    };
  },
})
export default class App extends mixins(WebPanelMixin) {
  created () {
    this.__unwatchEcho = this.$store.watch((state, getters) => getters.currentUser && getters.currentOrganization, (val) => {
      const { currentUser, currentOrganization } = this.$store.getters;
      if (currentUser && currentUser.pusherConfiguration && currentOrganization) {
        initEcho(this.$store);
        this.__unwatchEcho && this.__unwatchEcho();
      }
    }, { immediate: true });

    /**
     * Sets a page-scoped validation interaction mode. (default: aggressive)
     * Options are:
     *  Aggressive: Validation triggered on key press.
     *  Passive:    Validation triggered when form submitted.
     *  Lazy:       Validation triggered when user leaves input.
     *  Eager:      Combination of aggressive and lazy, it first validates when user leaves the input then if the input is
     *              invalid it will behave aggressively until the input is valid again and it will go back to being lazy.
     */
    setInteractionMode('lazy');

    Vue.prototype.$app = this;
    Vue.prototype.$config = this.config;
  }

  destroyed () {
    this.__unwatchEcho && this.__unwatchEcho();
  }

  topLink = { name: 'home' };

  get showUserSettings () {
    return !this.$permissions['content-only'];
  }

  get locations () {
    return this.$store.getters['entities/Location/all']();
  }

  getLocationId () {
    if (this.locations.length) {
      return this.$route.params.locationId || this.locations[0].id;
    }
  }

  get globalMessages () {
    if (!this.$store.getters.currentOrganization) return [];
    const messages = [];
    if (!this.$store.getters.currentOrganization.stripePaymentMethod) {
      messages.push({
        message: this.$t('ACTION REQUIRED: enable secure credit card processing to continue your subscription.'),
        background: 'yellow-gradient',
        buttonText: 'Re-enter credit card',
        action: ['goToBilling'],
      });
    }

    const agreementCount = this.$store.getters.pendingAgreementsCount;
    const pendingAgreements = this.$store.getters.pendingAgreements;
    if (agreementCount) {
      const remainingDays = this.$store.getters.remainingDaysToAcceptEULA;
      if (remainingDays > 0) {
        let message;
        const interpolationValues = { remaining: remainingDays, link: `<a class="link-color-inherit" href="https://svr.li/k/230" target="_blank" rel="noopener">${this.$t('Why am I seeing this?')}</a>` };
        if (remainingDays > 1 && agreementCount > 1) {
          message = this.$t('You have {remaining} days to agree to the new EULAs in your Library. {link}', interpolationValues);
        } else if (remainingDays > 1 && agreementCount <= 1) {
          message = this.$t('You have {remaining} days to agree to the new EULA in your Library. {link}', interpolationValues);
        } else if (remainingDays <= 1 && agreementCount <= 1) {
          message = this.$t('You have {remaining} day to agree to the new EULA in your Library. {link}', interpolationValues);
        } else if (remainingDays <= 1 && agreementCount > 1) {
          message = this.$t('You have {remaining} day to agree to the new EULAs in your Library. {link}', interpolationValues);
        }
        messages.push({
          html: true,
          message,
          buttonText: this.$t('Go to My Library'),
          action: agreementCount > 1 ? ['goToLibrary'] : ['goRequireEula', pendingAgreements[0].operatorGame],
        });
      }
    }
    return messages;
  }

  get isActive () { return this.$store.getters.currentOrganization && this.$store.getters.currentOrganization.isActive && !this.$store.getters.currentOrganization.isAccessSuspended; }

  get isAdminUser () {
    return this.$store.getters.currentUser?.accessLevel === 'admin';
  }

  get isStaffUser () {
    return this.$store.getters.currentUser?.accessLevel === 'staff';
  }

  get navigation () {
    if (!this.$store.getters.currentOrganization) return [];

    const showHome = this.$features['operator-panel-home-button'];
    const showBilling = !this.$permissions['subscription-exclusion'];
    const disable = !this.isActive;

    if (this.$permissions['content-only']) {
      return this.contentOnlyNavigation(showHome, disable);
    }

    if (this.$permissions.education || this.$permissions['enterprise-operator']) {
      return this.educationNavigation(showHome, showBilling, disable);
    }

    return this.operatorNavigation(showHome, showBilling, disable);
  };

  contentOnlyNavigation (showHome, disable) {
    return [
      ...((showHome) ? [nav.home(this.$t('Home'), disable)] : []),
      nav.myStations(this.$t('My Stations'), disable),
      {
        ...nav.content(this.$t('Content'), disable),
        children: [
          nav.marketplace(this.$t('Marketplace'), disable),
          nav.contentLibrary(this.$t('My Library'), disable),
          nav.tags(this.$t('Tags'), disable),
        ],
      },
    ];
  }

  educationNavigation (showHome, showBilling, disable) {
    const adminNav = (this.isAdminUser && [{
      ...nav.admin(this.$t('Admin')),
      children: [
        nav.organizationAnalytics(this.$t('Organization Analytics'), disable),
        nav.organizationSettings(this.$t('Organization Details'), disable),
        nav.locationIndex(this.$t('Locations'), disable),
        ...((showBilling) ? [nav.billing(this.$t('Billing'))] : []),
        nav.users(this.$t('Users'), disable),
      ],
    }]) || [];
    const managerNav = (!this.isStaffUser &&
      [
        ...(this.locations.map(({ id, title, waiversRequired, _hasSpringboardVRBookingIntegration }) => ({
          ...nav.location(title, id, disable),
          children: [
            nav.bookings(this.$t('Upcoming Bookings'), id, disable),
            nav.analytics(this.$t('Analytics'), id, disable),
            nav.experienceIndex(this.$t('Experiences'), id, disable),
            nav.stationIndex(this.$t('Stations'), id, disable),
            ...(!_hasSpringboardVRBookingIntegration ? [] : [
              nav.widgetIndex(this.$t('Widgets'), id, disable),
              ...((waiversRequired) ? [
                nav.waiverContentIndex(this.$t('Waiver'), id, disable),
              ] : []),
            ]),
          ],
        }))),
        {
          ...nav.content(this.$t('Content'), disable),
          children: [
            nav.contentLibrary(this.$t('My Library'), disable),
            nav.tags(this.$t('Tags'), disable),
          ],
        },
      ]) || [];
    return [
      [
        ...((showHome) ? [nav.home(this.$t('Home'), disable)] : []),
        ...managerNav,
        ...adminNav,
      ],
      [
        nav.monitor(this.$t('Monitor Stations'), window.sbvrenv.MONITOR_URL, this.getLocationId(), disable),
      ],
    ];
  }

  operatorNavigation (showHome, showBilling, disable) {
    const adminNav = (this.isAdminUser && [{
      ...nav.admin(this.$t('Admin')),
      children: [
        nav.organizationAnalytics(this.$t('Organization Analytics'), disable),
        nav.organizationSettings(this.$t('Organization Details'), disable),
        nav.locationIndex(this.$t('Locations'), disable),
        ...((showBilling) ? [nav.billing(this.$t('Billing'))] : []),
        nav.users(this.$t('Users'), disable),
      ],
    }]) || [];
    const managerNav = (!this.isStaffUser &&
      [...(this.locations.map(({ id, title, waiversRequired, _hasSpringboardVRBookingIntegration }) => ({
        ...nav.location(title, id, disable),
        children: [
          nav.bookings(this.$t('Upcoming Bookings'), id, disable),
          nav.analytics(this.$t('Analytics'), id, disable),
          nav.experienceIndex(this.$t('Experiences'), id, disable),
          nav.stationIndex(this.$t('Stations'), id, disable),
          ...((_hasSpringboardVRBookingIntegration) ? [
            nav.couponIndex(this.$t('Coupons'), id, disable),
            nav.discountIndex(this.$t('Discounts'), id, disable),
            nav.widgetIndex(this.$t('Widgets'), id, disable),
            ...((waiversRequired) ? [
              nav.waiverContentIndex(this.$t('Waiver'), id, disable),
            ] : []),
          ] : []),
        ],
      }))),
      {
        ...nav.content(this.$t('Content'), disable),
        children: [
          nav.marketplace(this.$t('Marketplace'), disable),
          nav.contentLibrary(this.$t('My Library'), disable),
          nav.tags(this.$t('Tags'), disable),
        ],
      }]) || [];
    return [
      [
        ...((showHome) ? [nav.home(this.$t('Home'), disable)] : []),
        ...managerNav,
        ...adminNav,
      ],
      [
        nav.monitor(this.$t('Monitor Stations'), window.sbvrenv.MONITOR_URL, this.getLocationId(), disable),
      ],
    ];
  }

  get userOptions () {
    if (this.$permissions['content-only']) {
      return [
        {
          name: this.$t('My Profile'),
          routerLink: { name: 'content-only-user-settings' },
        },
        {
          name: this.$t('Billing'),
          routerLink: { name: 'billing' },
        },
      ];
    } else {
      return [];
    }
  }

  get applications () {
    return [
      {
        name: this.$t('Operator Panel'),
        url: '/',
        current: true,
      },
    ];
  }
};
</script>

<style lang="postcss">
@import "core/shared/styles";
@import "core/shared/styles/static/content";

body .zEWidget-launcher {
  z-index: 200 !important;
}
body.-bottombar-active .zEWidget-launcher {
  bottom: 78px !important;
  @media (--tablet) {
    bottom: 98px !important;
  }
}
.link-color-inherit {
  color: inherit;
  &:hover {
    color: inherit;
  }
}
</style>
